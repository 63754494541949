// 3rd
import { Box, Spinner, useMultiStyleConfig, useStyleConfig } from '@chakra-ui/react';
import type { SystemStyleObject } from '@chakra-ui/react';
import type {
  ClearIndicatorProps as ReactSelectClearIndicatorProps,
  ControlProps as ReactSelectControlProps,
  DropdownIndicatorProps as ReactSelectDropdownIndicatorProps,
  GroupBase as ReactSelectGroupBase,
  LoadingIndicatorProps as ReactSelectLoadingIndicatorProps,
} from 'react-select';

// App - Types
import type { SizeProps } from '../types/types';

// App - Other
import { ChevronDownIcon, CloseIcon } from '@/components/atoms/icon';
import { useSize } from '../hooks/use-size';

export const Control = <
  Option,
  IsMulti extends boolean,
  Group extends ReactSelectGroupBase<Option>,
>(
  props: ReactSelectControlProps<Option, IsMulti, Group>
) => {
  const {
    className,
    cx,
    children,
    innerRef,
    innerProps,
    isDisabled,
    isFocused,
    menuIsOpen,
    selectProps: {
      chakraStyles,
      size: sizeProp,
      variant,
      focusBorderColor,
      errorBorderColor,
      isInvalid,
      isReadOnly,
    },
  } = props;

  const isOld = !!variant && typeof variant === 'string' && variant.includes('old');
  const size = useSize(sizeProp);
  const sizeValue = `${isOld ? 'old-' : ''}${size ? size : 'md'}`;

  const {
    field: { height, h, ...fieldStyles },
    group,
  } = useMultiStyleConfig('Input', {
    size: sizeValue,
    variant,
    focusBorderColor,
    errorBorderColor,
  });

  /**
   * `@chakra-ui/theme@3.2.0` introduced a breaking change that switched from using `h` to `height` for the Input sizing.
   *
   * We need to keep checking for either to maintain backwards compatibility.
   *
   * @see {@link https://github.com/chakra-ui/chakra-ui/releases/tag/%40chakra-ui%2Ftheme%403.2.0}
   */
  const minH = height || h;

  const initialSx: SystemStyleObject = {
    ...fieldStyles,
    ...group,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: 0,
    overflow: 'hidden',
    height: 'auto',
    minH,
    ...(isDisabled ? { pointerEvents: 'none' } : {}),
  };

  const sx = chakraStyles?.control ? chakraStyles.control(initialSx, props) : initialSx;

  return (
    <Box
      ref={innerRef}
      className={cx(
        {
          control: true,
          'control--is-disabled': isDisabled,
          'control--is-focused': isFocused,
          'control--menu-is-open': menuIsOpen,
        },
        className,
        'select-control'
      )}
      sx={sx}
      {...innerProps}
      data-focus={isFocused ? true : undefined}
      data-focus-visible={isFocused ? true : undefined}
      data-invalid={isInvalid ? true : undefined}
      data-disabled={isDisabled ? true : undefined}
      data-readonly={isReadOnly ? true : undefined}
    >
      {children}
    </Box>
  );
};

export const IndicatorSeparator = () => {
  return <></>;
};

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends ReactSelectGroupBase<Option>,
>(
  props: ReactSelectDropdownIndicatorProps<Option, IsMulti, Group>
) => {
  const {
    children,
    className,
    cx,
    innerProps,
    selectProps: {
      chakraStyles,
      useBasicStyles,
      size: sizeProp,
      focusBorderColor,
      errorBorderColor,
      variant,
    },
  } = props;

  const isOld = !!variant && typeof variant === 'string' && variant.includes('old');
  const size = useSize(sizeProp);
  const sizeValue = `${isOld ? 'old-' : ''}${size ? size : 'md'}`;

  const inputStyles = useMultiStyleConfig('Input', {
    size: sizeValue,
    variant,
    focusBorderColor,
    errorBorderColor,
  });

  // const iconSizes: SizeProps = {
  //   sm: '16px',
  //   md: '20px',
  //   lg: '24px',
  // };
  // const iconSize = iconSizes[size];

  const initialDropdownIndicatorSx: SystemStyleObject = {
    ...inputStyles.addon,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    h: '100%',
    borderRadius: 0,
    borderWidth: 0,
    // '& svg.chakra-icon': {
    //   width: iconSize,
    //   height: iconSize,
    // },
    ...(useBasicStyles && {
      background: 'transparent',
      boxShadow: 'none',
      padding: 'none',
      width: '24px',
      me: '8px',
      ms: '4px',
      cursor: 'inherit',
    }),
  };

  const dropdownIndicatorSx = chakraStyles?.dropdownIndicator
    ? chakraStyles.dropdownIndicator(initialDropdownIndicatorSx, props)
    : initialDropdownIndicatorSx;

  const initialDownChevronSx: SystemStyleObject = {
    height: '16px',
    width: '16px',
  };

  const downChevronSx = chakraStyles?.downChevron
    ? chakraStyles.downChevron(initialDownChevronSx, props)
    : initialDownChevronSx;

  return (
    <Box
      {...innerProps}
      className={cx(
        {
          indicator: true,
          'dropdown-indicator': true,
        },
        className,
        'select-dropdown-indicator'
      )}
      sx={dropdownIndicatorSx}
    >
      {children || <ChevronDownIcon sx={downChevronSx} aria-label="Menu cheveron" />}
    </Box>
  );
};

export const ClearIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends ReactSelectGroupBase<Option>,
>(
  props: ReactSelectClearIndicatorProps<Option, IsMulti, Group>
) => {
  const {
    children,
    className,
    cx,
    innerProps,
    selectProps: { chakraStyles, size: sizeProp, variant },
  } = props;

  const isOld = !!variant && typeof variant === 'string' && variant.includes('old');
  const size = useSize(sizeProp);
  const sizeValue = `${isOld ? 'old-' : ''}${size ? size : 'md'}`;

  const closeButtonStyles = useStyleConfig('CloseButton', {
    size: sizeValue,
  });

  const initialSx: SystemStyleObject = {
    ...closeButtonStyles,
    mx: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    borderRadius: 'xs',
    w: '20px',
    h: '20px',
  };

  const sx = chakraStyles?.clearIndicator
    ? chakraStyles.clearIndicator(initialSx, props)
    : initialSx;

  const initialIconStyles: SystemStyleObject = {
    width: '16px',
    height: '16px',
  };

  const iconSx: SystemStyleObject = chakraStyles?.crossIcon
    ? chakraStyles.crossIcon(initialIconStyles, props)
    : initialIconStyles;

  return (
    <Box
      role="button"
      className={cx(
        {
          indicator: true,
          'clear-indicator': true,
        },
        className,
        'select-clear-indicator'
      )}
      sx={sx}
      aria-label="Clear selected options"
      {...innerProps}
    >
      {children || <CloseIcon sx={iconSx} aria-label="Clear selected options" />}
    </Box>
  );
};

export const LoadingIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends ReactSelectGroupBase<Option>,
>(
  props: ReactSelectLoadingIndicatorProps<Option, IsMulti, Group>
) => {
  const {
    className,
    cx,
    innerProps,
    selectProps: { chakraStyles, size: sizeProp },
    color,
    emptyColor,
    speed,
    thickness,
    spinnerSize: propsSpinnerSize,
  } = props;

  const size = useSize(sizeProp);
  const spinnerSizes: SizeProps<string> = {
    sm: 'xs',
    md: 'sm',
    lg: 'md',
  };
  const spinnerSize = spinnerSizes[size];

  const initialSx: SystemStyleObject = { marginRight: 3 };

  const sx = chakraStyles?.loadingIndicator
    ? chakraStyles.loadingIndicator(initialSx, props)
    : initialSx;

  return (
    <Spinner
      className={cx(
        {
          indicator: true,
          'loading-indicator': true,
        },
        className,
        'select-loading-indicator'
      )}
      sx={sx}
      {...innerProps}
      size={propsSpinnerSize || spinnerSize}
      color={color}
      emptyColor={emptyColor}
      speed={speed}
      thickness={thickness}
    />
  );
};
