/* eslint-disable @typescript-eslint/no-explicit-any */
// React & Next
import { createContext, useContext, useRef } from 'react';
import type { ReactNode, ChangeEvent } from 'react';

// 3rd
import { chakra, Flex, forwardRef, useCheckbox, useCheckboxGroup } from '@chakra-ui/react';
import type { UseCheckboxProps, StyleProps } from '@chakra-ui/react';

// App - Other
import { Checkbox as CheckboxComponent } from './checkbox';
import { useHover } from '@/components/hooks/use-hover';

const ChecklistContext = createContext<{
  getCheckboxProps: (props?: Record<string, any>) => {
    [x: string]: any;
    onChange: (eventOrValue: ChangeEvent<HTMLInputElement> | string | number) => void;
  };
  onDoubleClick?: () => void;
  isDisabled?: boolean;
} | null>(null);

type ChecklistProps = {
  checked: string[];
  onChange: (checked: string[]) => void;
  onDoubleClick?: () => void;
  isDisabled?: boolean;
  children: ReactNode;
};

export const Checklist = ({
  checked,
  onChange,
  onDoubleClick,
  isDisabled,
  children,
}: ChecklistProps) => {
  const { getCheckboxProps, isDisabled: _isDisabled } = useCheckboxGroup({
    value: checked,
    onChange: (value: Array<string | number>) => {
      onChange(value as string[]);
    },
    isDisabled,
  });

  return (
    <ChecklistContext.Provider
      value={{
        getCheckboxProps,
        onDoubleClick,
        isDisabled: _isDisabled,
      }}
    >
      {children}
    </ChecklistContext.Provider>
  );
};

type CheckboxProps = UseCheckboxProps & {
  checkboxStyles?: StyleProps;
  value: string;
  children: ReactNode;
};

const Checkbox = forwardRef(({ value, children, checkboxStyles, ...props }: CheckboxProps, ref) => {
  const { getCheckboxProps, isDisabled, onDoubleClick } = useContext(ChecklistContext)!;

  const checkboxProps = getCheckboxProps({
    value,
    ...(props ? { isDisabled, ...props } : { isDisabled }),
  });

  const { getInputProps, getLabelProps, htmlProps } = useCheckbox({
    ...checkboxProps,
    ...(props ? { isDisabled, ...props } : { isDisabled }),
  });

  const labelProps = getLabelProps();
  const inputProps = getInputProps();

  return (
    <chakra.label
      display="flex"
      gap="sm"
      opacity={inputProps.disabled ? 0.5 : 1}
      cursor={inputProps.disabled ? 'not-allowed' : 'pointer'}
      onDoubleClick={(e) => {
        e.stopPropagation();

        onDoubleClick?.();
      }}
      ref={ref}
      {...htmlProps}
    >
      <input {...inputProps} hidden />

      <CheckboxComponent {...checkboxProps} {...checkboxStyles} />

      <Flex {...labelProps} onMouseDown={() => {}} w="100%">
        {children}
      </Flex>
    </chakra.label>
  );
});

Checklist.Checkbox = Checkbox;

const SilentCheckbox = ({ value, children, checkboxStyles, ...props }: CheckboxProps) => {
  const { getCheckboxProps, isDisabled } = useContext(ChecklistContext)!;
  const hoverRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(hoverRef);

  const checkboxProps = getCheckboxProps({
    value,
    ...(props ? { isDisabled, ...props } : { isDisabled }),
  });

  const { state } = useCheckbox({
    ...checkboxProps,
    ...(props ? { isDisabled, ...props } : { isDisabled }),
  });

  return (
    <Checkbox
      {...props}
      value={value}
      checkboxStyles={{
        ...checkboxStyles,
        visibility: isHover || state.isChecked ? 'visible' : 'hidden',
      }}
      ref={hoverRef}
    >
      {children}
    </Checkbox>
  );
};

Checklist.SilentCheckbox = SilentCheckbox;
