// React & Next
import type { ReactElement, ReactNode } from 'react';

// 3rd
import { FormControl, FormLabel, FormHelperText, FormErrorMessage } from '@chakra-ui/react';
import type { FormControlProps, FormLabelProps } from '@chakra-ui/react';

export type FieldProps = Omit<
  FormControlProps,
  | 'label'
  | 'isInvalid'
  | '_active'
  | '_hover'
  | '_disabled'
  | '_loading'
  | '_after'
  | '_before'
  | '_hidden'
  | '_placeholder'
  | '_placeholderShown'
  | '_selected'
  | '_selection'
  | '_checked'
  | '_visited'
  | '_valid'
  | '_invalid'
  | '_rtl'
  | '_ltr'
  | '_dark'
  | '_mediaReduceMotion'
  | '_mediaDark'
  | '_light'
  | '_vertical'
  | '_highlighted'
  | '_horizontal'
  | '_indeterminate'
  | '_empty'
  | '_even'
  | '_odd'
  | '_expanded'
  | '_last'
  | '_notLast'
  | '_notFirst'
  | '_first'
  | '_firstLetter'
  | '_focus'
  | '_focusVisible'
  | '_focusWithin'
  | '_fullScreen'
  | '_grabbed'
  | '_groupActive'
  | '_groupChecked'
  | '_groupDisabled'
  | '_groupFocus'
  | '_groupFocusVisible'
  | '_groupFocusWithin'
  | '_groupHover'
  | '_groupInvalid'
  | '_activeLink'
  | '_activeStep'
  | '_autofill'
  | '_peerActive'
  | '_peerChecked'
  | '_peerDisabled'
  | '_peerFocus'
  | '_peerFocusVisible'
  | '_peerFocusWithin'
  | '_peerHover'
  | '_peerInvalid'
  | '_peerPlaceholderShown'
  | '_pressed'
  | '_readOnly'
> & {
  children: ReactNode;
  label?: string | ReactElement;
  labelProps?: FormLabelProps;
  htmlFor?: string;

  helpText?: string;
  error?: string;
};

export const Field = ({
  label,
  labelProps,
  htmlFor,
  helpText,
  error,
  children,
  ...props
}: FieldProps) => {
  return (
    <FormControl {...props} isInvalid={!!error}>
      {label ? (
        <FormLabel htmlFor={htmlFor} {...labelProps}>
          {label}
        </FormLabel>
      ) : null}

      {children}

      {!error && helpText ? <FormHelperText>{helpText}</FormHelperText> : null}

      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};
