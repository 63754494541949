// App - Types
import type { CodeSource, KnowledgeSource } from '../knowledge-source';

export const DocumentSourcesDto = [
  'AzureDevOpsWikiPage',
  'Confluence',
  'GoogleDrive',
  'Notion',
  'SharePoint',
] as const;

export type DocumentSourceDto = (typeof DocumentSourcesDto)[number];

export const TicketSourcesDto = [
  'AzureDevOpsWorkItem',
  'Jira',
  'Trello',
  'Linear',
  'ServiceNow',
] as const;

export type TicketSourceDto = (typeof TicketSourcesDto)[number];

export const CodeSourcesDto = ['AzureDevOpsRepository', 'Github', 'GitLab'] as const;

export type CodeSourceDto = (typeof CodeSourcesDto)[number];

export const castCodeSourceDtoToCodeSource = (dto: CodeSourceDto): CodeSource => {
  if (dto === 'AzureDevOpsRepository') return 'azure_devops_repository';

  if (dto === 'Github') return 'github_pull_request';

  // if (dto === 'GitLab') return 'gitlab';

  return 'UNKNOWN';
};

export const KnowledgeSourcesDto = [
  ...DocumentSourcesDto,
  ...TicketSourcesDto,
  ...CodeSourcesDto,
  'UNKNOWN',
] as const;

export type KnowledgeSourceDto = (typeof KnowledgeSourcesDto)[number];

export const castKnowledgeSourceDtoToKnowledgeSource = (
  dto: KnowledgeSourceDto
): KnowledgeSource => {
  if (dto === 'AzureDevOpsWikiPage') return 'azure_devops_wiki_page';

  if (dto === 'AzureDevOpsWorkItem') return 'azure_devops_work_item';

  if (dto === 'AzureDevOpsRepository') return 'azure_devops_repository';

  if (dto === 'Confluence') return 'confluence';

  if (dto === 'GoogleDrive') return 'google_drive';

  if (dto === 'Jira') return 'jira';

  if (dto === 'Trello') return 'trello';

  if (dto === 'Linear') return 'linear';

  if (dto === 'Github') return 'github';

  if (dto === 'SharePoint') return 'share_point';

  if (dto === 'Notion') return 'notion';

  // if (dto === 'GitLab') return 'gitlab';

  if (dto === 'ServiceNow') return 'service_now';

  return 'UNKNOWN';
};

export const castKnowledgeSourceToKnowledgeSourceDto = (
  knowledgeSource: KnowledgeSource
): KnowledgeSourceDto => {
  if (knowledgeSource === 'azure_devops_wiki_page') return 'AzureDevOpsWikiPage';

  if (knowledgeSource === 'azure_devops_work_item') return 'AzureDevOpsWorkItem';

  if (knowledgeSource === 'azure_devops_repository') return 'AzureDevOpsRepository';

  if (knowledgeSource === 'confluence') return 'Confluence';

  if (knowledgeSource === 'google_drive') return 'GoogleDrive';

  if (knowledgeSource === 'jira') return 'Jira';

  if (knowledgeSource === 'trello') return 'Trello';

  if (knowledgeSource === 'linear') return 'Linear';

  if (knowledgeSource === 'github') return 'Github';

  if (knowledgeSource === 'share_point') return 'SharePoint';

  if (knowledgeSource === 'notion') return 'Notion';

  // if (knowledgeSource === 'gitlab') return 'GitLab';

  if (knowledgeSource === 'service_now') return 'ServiceNow';

  return 'UNKNOWN';
};
