// React & Next
import { useState } from 'react';
import type { RefObject } from 'react';

// App - Other
import { useEventListener } from './use-event-listener';
import { useDebounceCallback } from './use-debounce-callback';

type UseHoverOptions = {
  enterDelay?: number;
  exitDelay?: number;
};

export function useHover<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>,
  { enterDelay = 0, exitDelay = 0 }: UseHoverOptions = {}
): boolean {
  const [value, _setValue] = useState<boolean>(false);

  const setHoverEnter = useDebounceCallback(() => _setValue(true), enterDelay, { leading: true });
  const setHoverExit = useDebounceCallback(() => _setValue(false), exitDelay, { trailing: true });

  const handleMouseEnter = () => {
    setHoverExit.cancel();
    setHoverEnter();
  };

  const handleMouseLeave = () => {
    setHoverEnter.cancel();
    setHoverExit();
  };

  useEventListener('mouseenter', handleMouseEnter, elementRef);
  useEventListener('mouseleave', handleMouseLeave, elementRef);

  return value;
}
